const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;

export async function getStrategicDecks(pageData) {
	const response = await fetch(`${API_BASE_URL}/strategic-decks/getDecks/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the strategic decks.');
	}

	const data = await response.json();

	// if (!data['success']) { // Manually created and returned attributes
	// 	throw new Error(data['status_code'] + ': ' + data['status']);
	// }
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getStrategicDeck(pageData) {
	const response = await fetch(`${API_BASE_URL}/strategic-decks/getDeck/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the strategic deck.');
	}

	const data = await response.json();

	return data;
}

export async function createStrategicDeck(pageData) {
	const response = await fetch(`${API_BASE_URL}/strategic-decks/createDeck/`, {
		method: 'POST',
		body: pageData,
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not add the strategic deck.');
	}

	const data = await response.json();

	return data;
}

export async function modifyStrategicDeck(pageData) {
	const response = await fetch(`${API_BASE_URL}/strategic-decks/modifyDeck/`, {
		method: 'POST',
		body: pageData,
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the strategic deck.');
	}

	const data = await response.json();

	return data;
}

export async function removeStrategicDeck(pageData) {
	const response = await fetch(`${API_BASE_URL}/strategic-decks/removeDeck/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the strategic deck.');
	}

	const data = await response.json();

	return data;
}
