/**
***************************************************************************
* NAME 			: Team
* PROPS	 		: members (array)
*				: isLoading (status === 'pending')
* RETURNS 		: Team members to be displayed in the dashboard header
* LAST MODIFIED 	: February 22, 2024
***************************************************************************
*/

import { Link } from 'react-router-dom';
import LoadingSpinner from '../../UI/LoadingSpinner';

const Team = (props) => {
	return (
		<>
			{ props.isLoading && <LoadingSpinner /> }

			{ !props.isLoading && (props.members.length > 0) &&
				<Link to="/contact-us/" className="header_contact_team">
					<p>Contact your ML Team</p>
					<div className="headshots">
						{props.members.map((member) =>
							<img src={'/img/headshots-ml/' + member.firstName.charAt(0).toLowerCase() + member.lastName.toLowerCase() + '.png'} alt={member.name} width="48" height="48" key={member.userId} />
						)}
					</div>
				</Link>
			}
		</>
	)
}

export default Team;