/**
***************************************************************************
* NAME 			: Logos
* PROPS	 		: name (string)
*				: isLoading (status === 'pending')
* RETURNS 		: Image in the header containing company logos
* LAST MODIFIED 	: March 26, 2024
***************************************************************************
*/

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import LoadingSpinner from '../../UI/LoadingSpinner';

const Logos = (props) => {
	const authCtx = useContext(AuthContext);

	return (
		<>
			{ props.isLoading && <LoadingSpinner /> }

			{ !props.isLoading &&
				<div className="header_cobrand_logo">
					<Link to="/"><img src={"/img/client-logos/" + authCtx.dashboard + "/logo-lockup.svg"} alt={(props.name.includes("Media Logic")) ? "Media Logic" : "Media Logic | " + props.name} /></Link>
				</div>
			}
		</>
	)
}

export default Logos;