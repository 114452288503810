/**
***************************************************************************
* NAME 			: StrategicDeckSummary
* PROPS	 		: None
* RETURNS 		: Display the strategic decks on the dashboard
* LAST MODIFIED 	: March 21, 2024
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { getStrategicDecks } from '../../../api/strategic-decks';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const StrategicDeckSummary = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [decks, setDecks] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { sendRequest, status, data, error } = useHttp(getStrategicDecks);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	error
				// )
				setErrorMessage(error);
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// )
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
				// console.log('DECK DATA');
				// console.log(data['data']);

				setErrorMessage("");
				setDecks(data['data']);
			}
			setIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token,
			dashboard: authCtx.dashboard,
			top: 5,
			display: "summary"
		};

		sendRequest(requestData);
	}, [sendRequest]);

	return (
		<>
			{ isLoading && <LoadingSpinner /> }
			{ !isLoading && errorMessage &&
				<article>
					<div className="article_copy">
						<p>{errorMessage}</p>
					</div>
				</article>
			}

			{ !isLoading && !errorMessage && (decks.length == 0) &&
				<article>
					<div className="article_copy">
						<p>No strategic decks to display</p>
					</div>
				</article>
			}
			{ !isLoading && !errorMessage && (decks.length > 0) &&
				<>
					<Splide options={ { rewind: true } } aria-label="Strategic Decks">
						{decks.map((deck) => 
							<SplideSlide key={deck.id}>
								<article>
									<div className="article_copy">
										<h3 className="article_head strategic-decks"><Link to={'/strategic-decks/' + deck.id} className={deck.fileClass}>{deck.name}</Link></h3>
										<p><em>{deck.lastAction} on {deck.displayLastActionMoment}</em></p>
									</div>
								</article>
							</SplideSlide>
						)}
					</Splide>

					<p><Link to="/strategic-decks/">See all</Link></p>
				</>
			}
		</>
	)
}

export default StrategicDeckSummary;