/**
***************************************************************************
* NAME 			: Header
* PROPS	 		: None
* RETURNS 		: Container for the header components
* LAST MODIFIED 	: February 22, 2024
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/auth-context';
import NotificationContext from '../context/notification-context';
import useHttp from '../hooks/use-http';
import Navigation from '../components/containers/header/Navigation';
import Logos from '../components/containers/header/Logos';
import Team from '../components/containers/header/Team';
import { getHeader } from '../api/dashboard';

const Header = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [navigationItems, setNavigationItems] = useState([]);
	const [name, setName] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [showUsers, setShowUsers] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { sendRequest, status, data, error } = useHttp(getHeader);

	const requestData = {
		token: authCtx.token,
		dashboard: authCtx.dashboard
	};

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('NAVIGATION DATA');
				// console.log(data['data']);

				setNavigationItems(data['data']['navigation']);
				setName(data['data']['name']);
				setTeamMembers(data['data']['members']);
				setShowUsers(data['data']['showusers']);
			}
			setIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest(requestData);
	}, [sendRequest, authCtx.dashboard]); // authCtx.dashboard added to refresh the header when the dashboard changes (removed separate handler)

	/**
	**********
	HANDLERS
	**********
	*/
	// const refreshHandler = () => {
	// 	setNavigationItems([]); // force a refresh
	// 	setName([]); // force a refresh
	// 	setTeamMembers([]); // force a refresh

	// 	sendRequest(requestData);
	// };

	return (
		<header>
			{
				<>
					<Navigation items={navigationItems} showUsers={showUsers} isLoading={status === 'pending'} />
					<Logos name={name} isLoading={status === 'pending'} />
					<Team members={teamMembers} isLoading={status === 'pending'} />
				</>
			}
		</header>
	)
}

export default Header;