const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
 
export async function getDashboards(pageData) {
	const response = await fetch(`${API_BASE_URL}/user/getDashboards/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the dashboards available.');
	}

	const data = await response.json();

	// if (!data['success']) { // Manually created and returned attributes
	// 	throw new Error(data['status_code'] + ': ' + data['status']);
	// }
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getDefaultDashboard(pageData) {
	const response = await fetch(`${API_BASE_URL}/user/getDefaultDashboard/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the default dashboard.');
	}

	const data = await response.json();

	return data;
}

export async function updateDefaultDashboard(pageData) {
	const response = await fetch(`${API_BASE_URL}/user/updateDefaultDashboard/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not update the default dashboard for the user.');
	}

	const data = await response.json();

	return data;
}

export async function getUser(pageData) {
	const response = await fetch(`${API_BASE_URL}/user/getUser/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the client user information.');
	}

	const data = await response.json();

	return data;
}

export async function createUser(pageData) {
	const response = await fetch(`${API_BASE_URL}/user/createUser/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not create the client user.');
	}

	const data = await response.json();

	return data;
}

export async function modifyUser(pageData) {
	const response = await fetch(`${API_BASE_URL}/user/modifyUser/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the client user.');
	}

	const data = await response.json();

	return data;
}

export async function removeUser(pageData) {
	const response = await fetch(`${API_BASE_URL}/user/removeUser/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the client user.');
	}

	const data = await response.json();

	return data;
}

