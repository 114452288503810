/**
***************************************************************************
* NAME 			: CompetiscanReportSummary
* PROPS	 		: None
* RETURNS 		: Display the competiscan reports on the dashboard
* LAST MODIFIED 	: March 21, 2024
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { getCompetiscanReports } from '../../../api/competiscan-reports';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const CompetiscanReportSummary = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [reports, setReports] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { sendRequest, status, data, error } = useHttp(getCompetiscanReports);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	error
				// )
				setErrorMessage(error);
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// )
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
//				console.log('REPORT DATA');
//				console.log(data['data']);

				setErrorMessage("");
				setReports(data['data']);
			}
			setIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token,
			dashboard: authCtx.dashboard,
			top: 5,
			display: "summary"
		};

		sendRequest(requestData);
	}, [sendRequest]);

	return (
		<>
			{ isLoading && <LoadingSpinner /> }
			{ !isLoading && errorMessage &&
				<article>
					<div className="article_copy">
						<p>{errorMessage}</p>
					</div>
				</article>
			}

			{ !isLoading && !errorMessage && (reports.length == 0) &&
				<article>
					<div className="article_copy">
						<p>No competiscan reports to display</p>
					</div>
				</article>
			}
			{ !isLoading && !errorMessage && (reports.length > 0) &&
				<>
					<Splide options={ { rewind: true } } aria-label="Competiscan Reports">
						{reports.map((report) => 
							<SplideSlide key={report.id}>
								<article>
									<div className="article_copy">
										<h3 className="article_head competiscan-reports"><Link to={'/competiscan-reports/' + report.id} className={report.fileClass}>{report.name}</Link></h3>
										<p><em>{report.lastAction} on {report.displayLastActionMoment}</em></p>
									</div>
								</article>
							</SplideSlide>
						)}
					</Splide>

					<p><Link to="/competiscan-reports/">See all</Link></p>
				</>
			}
		</>
	)
}

export default CompetiscanReportSummary;