const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
 
export async function callHealthcareInsights(pageData) {
	const response = await fetch(`${API_BASE_URL}/healthcare-insights/callInsights/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the healthcare insights.');
	}

	const data = await response.json();

	// if (!data['success']) { // Manually created and returned attributes
	// 	throw new Error(data['status_code'] + ': ' + data['status']);
	// }
	// console.log('DATA');
	// console.log(data);
	return data;
}
