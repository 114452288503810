import React, {useState, useEffect, useCallback} from 'react';

//const logoutTime = (1 * 60 * 60 * 1000); // 1 hour * 60 minutes * 60 seconds * 1000 milliseconds
const logoutTime = (60 * 60 * 1000); // 1 hour * 60 minutes * 60 seconds * 1000 milliseconds
const priorNotificationTime = (5 * 60 * 1000); // 5 minutes * 60 seconds * 1000 milliseconds
let logoutTimer;
let notificationTimer;

const AuthContext = React.createContext({ // Could be a string but in this case, it's an object
	token: '',
	dashboard: '',
	isLoggedIn: false,
	trustedBrowser: false,
	timeoutNotification: false,
	login: () => {}, // Just doing this here for IDE help
	logout: () => {}, // Just doing this here for IDE help
	trustBrowser: () => {}, // Just doing this here for IDE help
	updateDashboard: () => {}, // Just doing this here for IDE help
	updateExpiration: () => {} // Just doing this here for IDE help
});

const calculateRemainingTime = (expiration) => {
// console.log('calculateRemainingTime: expiration: ' + expiration);
	const currentTime = new Date().getTime(); // current time stamp in milliseconds
	const adjExpiration = new Date(expiration).getTime();
	const remainingTime = adjExpiration - currentTime;
// console.log('calculateRemainingTime: ' + remainingTime);
	return remainingTime;
}

const retrieveStoredToken = () => {
	const storedToken = localStorage.getItem('mlportal-token');
	const storedDashboard = localStorage.getItem('mlportal-dashboard');
	const storedExpirationDate = localStorage.getItem('mlportal-expiration');

	// const storedUserData = JSON.parse(localStorage.getItem('userData'));
	// if (storedUserData && storedUserData.token) {
	// }

	const remainingTime = calculateRemainingTime(storedExpirationDate);
// console.log('retrieveStoredToken: remainingTime: ' + remainingTime);
	const notificationTime = remainingTime - priorNotificationTime;

	// if (remainingTime <= 60000)  {
	if (remainingTime <= 0)  {
		localStorage.removeItem('mlportal-token');
		localStorage.removeItem('mlportal-dashboard');
		localStorage.removeItem('mlportal-expiration');
		return null;
	}

	return {
		token: storedToken,
		dashboard: storedDashboard,
		duration: remainingTime,
		notification: notificationTime
	};
}

export const AuthContextProvider = (props) => {
	const tokenData = retrieveStoredToken();
	let initToken;
	let initDashboard;
	if (tokenData) {
		initToken = tokenData.token;
		initDashboard = tokenData.dashboard;
	}
	const [token, setToken] = useState(initToken);
	const [dashboard, setDashboard] = useState(initDashboard);
	const [trustedBrowser, setTrustedBrowser] = useState(false);
	const [timeoutNotification, settimeoutNotification] = useState(false);

	const userIsLoggedIn = !!token; // !! converts value to boolean. If empty, false; not empty true.

	const logoutHandler = () => {
		setToken(null);
		localStorage.removeItem('mlportal-token');
		localStorage.removeItem('mlportal-dashboard');
		localStorage.removeItem('mlportal-expiration');

		settimeoutNotification(false);

		if (logoutTimer) {
			clearTimeout(logoutTimer);
			clearTimeout(notificationTimer);
		}

		let rn = Math.floor(Math.random() * 1000000) + 1;
		window.location.href = "/?rn=" + rn.toString();
	}

	const notificationHandler = useCallback(() => {
// console.log('auth: notificationHandler: settimeoutNotification: true');
		settimeoutNotification(true);

		clearTimeout(notificationTimer);
// console.log('auth: notificationHandler END');
	}, []);

	const loginHandler = (token, dashboard, expiration) => {
		setToken(token);
		setDashboard(dashboard);
		localStorage.setItem('mlportal-token', token);
		localStorage.setItem('mlportal-dashboard', dashboard);
		localStorage.setItem('mlportal-expiration', expiration);
		// localStorage.setItem(
		// 	'userData',
		// 	JSON.stringify({token: token, expiration: expiration})
		// );

		const remainingTime = calculateRemainingTime(expiration);
		const notificationTime = remainingTime - priorNotificationTime;
// console.log('LOGIN HANDLER: REMAINING TIME: ' + remainingTime);
// console.log('LOGIN HANDLER: NOTIFICATION TIME: ' + notificationTime);

		logoutTimer = setTimeout(logoutHandler, remainingTime);
		notificationTimer = setTimeout(notificationHandler, notificationTime);
	}

	const browserHandler = (trust) => {
		setTrustedBrowser(trust);
	}

	const dashboardHandler = (dashboard) => {
		setDashboard(dashboard);
		localStorage.setItem('mlportal-dashboard', dashboard);

//		let rn = Math.floor(Math.random() * 1000000) + 1;
//		window.location.href = window.location.pathname + "?rn=" + rn.toString();
	}

	const expirationHandler = () => {
		const expiration = new Date(new Date().getTime() + logoutTime);
		localStorage.setItem('mlportal-expiration', expiration);

		const remainingTime = calculateRemainingTime(expiration);
		const notificationTime = remainingTime - priorNotificationTime;
// console.log('EXPERATION HANDLER: REMAINING TIME: ' + remainingTime);
// console.log('EXPERATION HANDLER: NOTIFICATION TIME: ' + notificationTime);

		clearTimeout(logoutTimer);
		clearTimeout(notificationTimer);
		logoutTimer = setTimeout(logoutHandler, remainingTime);
		notificationTimer = setTimeout(notificationHandler, notificationTime);

		if (timeoutNotification) {
// console.log('auth: notificationHandler: settimeoutNotification: false');
			// Clear the timeout warning
			settimeoutNotification(false);
		}
	}

	useEffect(() => {
		if (tokenData) {
// console.log('USE EFFECT: DURATION: ' + tokenData.duration);
// console.log('USE EFFECT: NOTIFICATION: ' + tokenData.notification);

			clearTimeout(logoutTimer);
			clearTimeout(notificationTimer);
			logoutTimer = setTimeout(logoutHandler, tokenData.duration);
			if (tokenData.notification <= 0) {
				notificationHandler();
			} else {
				notificationTimer = setTimeout(notificationHandler, tokenData.notification);
			}
		} else {
			clearTimeout(logoutTimer);
			clearTimeout(notificationTimer);
		}
	}, [tokenData, logoutHandler, notificationHandler]);

	const contextValue = {
		token,
		dashboard,
		isLoggedIn: userIsLoggedIn,
		trustedBrowser,
		timeoutNotification,
		login: loginHandler,
		logout: logoutHandler,
		trustBrowser: browserHandler,
		updateDashboard: dashboardHandler,
		updateExpiration: expirationHandler
	}

	return (
		<AuthContext.Provider value={contextValue}>
			{props.children}
		</AuthContext.Provider>
	)
};

export default AuthContext;