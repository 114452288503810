/**
***************************************************************************
* NAME 			: CountyAnalysisSummary
* PROPS	 		: -- (array)
* RETURNS 		: Display for the home card on the dashboard
* LAST MODIFIED 	: March 26, 2024
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';

const CountyAnalysisSummary = () => {
	const authCtx = useContext(AuthContext);

	return (
		<article>
			<>
				<div className="article_image">
					<img src={"/img/county-analysis/" + authCtx.dashboard + "/map.jpg"} alt="" width="998" height="674" />
				</div>
				<div className="article_copy">
					<p><Link to="/county-analysis/">View details</Link></p>
				</div>
			</>
		</article>
  	)
}

export default CountyAnalysisSummary;