const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;

export async function getCompetiscanReports(pageData) {
	const response = await fetch(`${API_BASE_URL}/competiscan-reports/getReports/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the competiscan reports.');
	}

	const data = await response.json();

	// if (!data['success']) { // Manually created and returned attributes
	// 	throw new Error(data['status_code'] + ': ' + data['status']);
	// }
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getCompetiscanReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/competiscan-reports/getReport/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the competiscan report.');
	}

	const data = await response.json();

	return data;
}

export async function createCompetiscanReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/competiscan-reports/createReport/`, {
		method: 'POST',
		body: pageData,
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not add the competiscan report.');
	}

	const data = await response.json();

	return data;
}

export async function modifyCompetiscanReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/competiscan-reports/modifyReport/`, {
		method: 'POST',
		body: pageData,
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the competiscan report.');
	}

	const data = await response.json();

	return data;
}

export async function removeCompetiscanReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/competiscan-reports/removeReport/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the competiscan report.');
	}

	const data = await response.json();

	return data;
}
