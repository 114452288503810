import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth-context'
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { authenticateUser } from '../../../api/authentication';

const LoginForm = (props) => {
	const authCtx = useContext(AuthContext);

	const [formProcessing, setFormProcessing] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [loginMessage, setLoginMessage] = useState(props.loginMessage);

	const logoutTime = (1 * 60 * 60 * 1000); // 1 hour * 60 minutes * 60 seconds * 1000 milliseconds
	
	const { sendRequest, status, data, error } = useHttp(authenticateUser);

	useEffect(() => {
		if (status === 'completed') {
			// console.log(data);
			if (error !== null) {
				setErrorMessage(error);
			} else if (!data['success']) {
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else if (!data['data']['access']) {
				setErrorMessage(data['data']['message']);
			} else {
				// Log In
				// console.log('LOG IN');
				// console.log(data['data']);

				setErrorMessage(null);

				const expiration = new Date(new Date().getTime() + logoutTime) // + 1 hour
				// console.log('expiration');
				// console.log(expiration);
				authCtx.login(data['data']['token'], data['data']['dashboardKey'], expiration.toISOString());

				if (data['data']['browser']) {
					authCtx.trustBrowser(true);
					// console.log('AUTH');
					// console.log(authCtx);
				} else {
					props.onLogin(data['data']['message']);
				}
			}

			setFormProcessing(false);
		}
	}, [status, error, data, logoutTime, authCtx, props]);

	// Requirement types
	const isNotEmpty = value => value.trim() !== '';
	const isEmail = value => value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

	const {
		value: enteredEmail,
		isValid: emailIsValid,
		hasError: emailHasError,
		valueChangeHandler: emailChangedHandler,
		inputBlurHandler: emailBlurHandler,
		valueSubmitHandler: emailSubmitHandler
		// reset: resetEmail
	} = useInput(isEmail); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredPassword,
		isValid: passwordIsValid,
		hasError: passwordHasError,
		valueChangeHandler: passwordChangedHandler,
		inputBlurHandler: passwordBlurHandler,
		valueSubmitHandler: passwordSubmitHandler,
		reset: resetPassword
	} = useInput(isNotEmpty); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (emailIsValid && passwordIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);
		// setLoginMessage(null);

		// Added (if form is able to be submitted first before error checking)
 		emailSubmitHandler();
		passwordSubmitHandler();

		if (!formIsValid) {
			setFormErrorMessage(defaultFormErrorMessage);
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		} else {
			setFormErrorMessage(null);
		}

		const formData = {
			email: enteredEmail,
			password: enteredPassword,
			via: "portal"
			// name: lnameRef.current.value,
		};

		sendRequest(formData);

		// props.onAddTest(testEntry);

		// Reset values
		// resetEmail();
		resetPassword();
	}

	const forgotHandler = () => {
		props.onForgot(true);
	}

	const personalPinHandler = () => {
		props.onPin(0);
	}

//	const emailInputClasses = emailHasError ? 'form-control invalid' : 'form-control';
//	const passwordInputClasses = passwordHasError ? 'form-control invalid' : 'form-control';

	const defaultFormErrorMessage = 'Please fix the errors on the form above.';
	const emailLabelClass = emailHasError ? 'form-error' : '';
	const emailErrorClasses = emailHasError ? 'form-description' : 'form-description visually-hidden';
	const passwordLabelClass = passwordHasError ? 'form-error' : '';
	const passwordErrorClasses = passwordHasError ? 'form-description' : 'form-description visually-hidden';
	const formErrorClasses = (emailHasError || passwordHasError) ? 'form-description' : 'form-description visually-hidden';
	const errorClasses = (!!errorMessage) ? 'form-description' : 'form-description visually-hidden';

	return (
		<>
			{/* <p className="form-required_text"><span className="form-required_star" aria-hidden="true">*</span>Fill the required fields</p> */}

			<h2>Log in to get started</h2>
			
			{(loginMessage != null) && 
				<p className="instructions">{loginMessage}</p>
			}

			<p className="form-required_text visually-hidden">All fields required</p>

			<div className={errorClasses}>
				<p className="form-feedback-sr visually-hidden" aria-live="assertive">{errorMessage}</p>
				<p className="form-feedback-msg" aria-hidden="true">{errorMessage}</p>
			</div>

			<form onSubmit={submitHandler} autoCapitalize="none" autoCorrect="off" spellCheck="false">
				<div>
					<label htmlFor="email" className={emailLabelClass}>
						Email address {/* <span className="form-required_star" aria-hidden="true">*</span> */}
						<span className="form-error_icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 44.4"><path d="M49.4 38 28.7 2.1C27.5.1 24.9-.6 22.9.5c-.6.4-1.2.9-1.6 1.6L.6 38c-1.2 2-.5 4.6 1.6 5.8.6.4 1.4.6 2.1.6h41.5c2.4 0 4.3-1.9 4.3-4.3-.1-.7-.3-1.4-.7-2.1zm-24.4.7c-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7zm2.7-11.6c0 1.3-1.1 2.4-2.4 2.4h-.5c-1.3 0-2.4-1.1-2.4-2.4V15.6c0-1.3 1.1-2.4 2.4-2.4h.5c1.3 0 2.4 1.1 2.4 2.4v11.5z" fill="red" className="alert_triangle" /><path fill="none" d="M0 0h50v44.4H0z"/></svg>
						</span>
					</label>
					<input
						type="email"
						name="email"
						id="email"
						aria-describedby="email-error-container"
						aria-required="true"
						className={emailLabelClass}
						onChange={emailChangedHandler}
						onBlur={emailBlurHandler}
						value={enteredEmail}
					/>
					<div id="email-error-container" className={emailErrorClasses}>
						{emailHasError &&
							// <p className="error-text">Email must include @.</p>
							<p className="form-error">Valid email address required</p>
						}
					</div>
				</div>

				<div>
					<label htmlFor="pw" className={passwordLabelClass}>
						Password
						<span className="form-error_icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 44.4"><path d="M49.4 38 28.7 2.1C27.5.1 24.9-.6 22.9.5c-.6.4-1.2.9-1.6 1.6L.6 38c-1.2 2-.5 4.6 1.6 5.8.6.4 1.4.6 2.1.6h41.5c2.4 0 4.3-1.9 4.3-4.3-.1-.7-.3-1.4-.7-2.1zm-24.4.7c-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7zm2.7-11.6c0 1.3-1.1 2.4-2.4 2.4h-.5c-1.3 0-2.4-1.1-2.4-2.4V15.6c0-1.3 1.1-2.4 2.4-2.4h.5c1.3 0 2.4 1.1 2.4 2.4v11.5z" fill="red" className="alert_triangle" /><path fill="none" d="M0 0h50v44.4H0z"/></svg>
						</span>
					</label>
					<input
						type="password"
						name="password"
						id="pw"
						aria-describedby="password-error-container"
						aria-required="true"
						className={passwordLabelClass}
						onChange={passwordChangedHandler}
						onBlur={passwordBlurHandler}
						value={enteredPassword}
					/>
					<div id="password-error-container" className={passwordErrorClasses}>
						{passwordHasError &&
							// <p className="error-text">Email must include @.</p>
							<p className="form-error">Password required</p>
						}
					</div>
				</div>

				<div className={formErrorClasses}>
					<p className="form-feedback-sr visually-hidden" aria-live="assertive">{formErrorMessage}</p>
					<p className="form-feedback-msg" aria-hidden="true">{formErrorMessage}</p>
				</div>

				<div className="button-wrap">
					<button type="submit" className="primary withspinner" disabled={formProcessing}>
						<span>Log In</span>
						{formProcessing && <LoadingSpinner />}
					</button>
				</div>
			</form>

			<div className="forgot_pw_wrap">
				<div className="forgot_pw">
					<button className="forgotpassword" type="button" onClick={forgotHandler}>
						<span><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.125 1.125H6v.75H1.875V6h-.75V1.125ZM16.125 1.875H12v-.75h4.875V6h-.75V1.875ZM16.125 16.125V12h.75v4.875H12v-.75h4.125ZM1.875 16.125V12h-.75v4.875H6v-.75H1.875ZM7.14384 4.51884C7.63613 4.02656 8.30381 3.75 9 3.75c.69619 0 1.3639.27656 1.8562.76884.4922.49229.7688 1.15997.7688 1.85616 0 .69619-.2766 1.36387-.7688 1.85616C10.3639 8.72344 9.69619 9 9 9c-.69619 0-1.36387-.27656-1.85616-.76884C6.65156 7.73887 6.375 7.07119 6.375 6.375c0-.69619.27656-1.36387.76884-1.85616ZM9 4.5c-.49728 0-.97419.19754-1.32583.54917-.35163.35164-.54917.82855-.54917 1.32583 0 .49728.19754.97419.54917 1.32583C8.02581 8.05246 8.50272 8.25 9 8.25c.49728 0 .97419-.19754 1.3258-.54917.3517-.35164.5492-.82855.5492-1.32583 0-.49728-.1975-.97419-.5492-1.32583C9.97419 4.69754 9.49728 4.5 9 4.5ZM8.99972 10.5c1.61218-.0023 3.20458.3552 4.66108 1.0465l.2142.1017v2.2268h-9.75v-2.2268l.21411-.1017c1.4134-.6713 2.99366-1.0464 4.66061-1.0465Zm4.12528 1.6254c-1.297-.5788-2.7024-.8775-4.12445-.8754H9c-1.46967 0-2.86522.3127-4.125.8753v.9997h8.25v-.9996Z" fill="#fff"/></svg></span>Forgot Password?
					</button>
					{props.pinAvailable &&
						<button className="forgotpassword" type="button" onClick={personalPinHandler}>
							<span><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.125 1.125H6v.75H1.875V6h-.75V1.125ZM16.125 1.875H12v-.75h4.875V6h-.75V1.875ZM16.125 16.125V12h.75v4.875H12v-.75h4.125ZM1.875 16.125V12h-.75v4.875H6v-.75H1.875ZM7.14384 4.51884C7.63613 4.02656 8.30381 3.75 9 3.75c.69619 0 1.3639.27656 1.8562.76884.4922.49229.7688 1.15997.7688 1.85616 0 .69619-.2766 1.36387-.7688 1.85616C10.3639 8.72344 9.69619 9 9 9c-.69619 0-1.36387-.27656-1.85616-.76884C6.65156 7.73887 6.375 7.07119 6.375 6.375c0-.69619.27656-1.36387.76884-1.85616ZM9 4.5c-.49728 0-.97419.19754-1.32583.54917-.35163.35164-.54917.82855-.54917 1.32583 0 .49728.19754.97419.54917 1.32583C8.02581 8.05246 8.50272 8.25 9 8.25c.49728 0 .97419-.19754 1.3258-.54917.3517-.35164.5492-.82855.5492-1.32583 0-.49728-.1975-.97419-.5492-1.32583C9.97419 4.69754 9.49728 4.5 9 4.5ZM8.99972 10.5c1.61218-.0023 3.20458.3552 4.66108 1.0465l.2142.1017v2.2268h-9.75v-2.2268l.21411-.1017c1.4134-.6713 2.99366-1.0464 4.66061-1.0465Zm4.12528 1.6254c-1.297-.5788-2.7024-.8775-4.12445-.8754H9c-1.46967 0-2.86522.3127-4.125.8753v.9997h8.25v-.9996Z" fill="#fff"/></svg></span>Use PIN
						</button>
					}
				</div>
			</div>
		</>
	)
}

export default LoginForm;