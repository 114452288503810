const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
 
export async function getHeader(pageData) {
	const response = await fetch(`${API_BASE_URL}/dashboard/getHeader/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the header information for the dashboard.');
	}

	const data = await response.json();

	// if (!data['success']) { // Manually created and returned attributes
	// 	throw new Error(data['status_code'] + ': ' + data['status']);
	// }
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getComponents(pageData) {
	const response = await fetch(`${API_BASE_URL}/dashboard/getComponents/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the compoents for the dashboard.');
	}

	const data = await response.json();

	return data;
}

export async function getTeamMembers(pageData) {
	const response = await fetch(`${API_BASE_URL}/dashboard/getTeamMembers/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the user listing.');
	}

	const data = await response.json();

	return data;
}
export async function getUsers(pageData) {
	const response = await fetch(`${API_BASE_URL}/dashboard/getUsers/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the user listing.');
	}

	const data = await response.json();

	return data;
}
