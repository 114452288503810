import { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/auth-context';
import NotificationContext from '../context/notification-context';
import useHttp from '../hooks/use-http';
import HomeCard from '../components/UI/HomeCard';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import { getComponents } from '../api/dashboard';

const Home = () => {
	// Set header information and body classes
	document.title = "Client Portal | Powered by Media Logic";
	document.body.className = "home";

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [components, setComponents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const { sendRequest, status, data, error } = useHttp(getComponents);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('COMPONENT DATA');
				// console.log(data['data']);

				setComponents(data['data']);
			}
			setIsLoading(false);

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		setIsLoading(true);

		const requestData = {
			token: authCtx.token,
			dashboard: authCtx.dashboard
		};

		sendRequest(requestData);
	}, [sendRequest, authCtx.dashboard]);

	return (
		<main>
			<div className="constrained">
				<h1 className="visuallyhidden">Dashboard</h1>

				<div className="cardwrap">
					{ isLoading && <LoadingSpinner /> }
					
					{ !isLoading && (components.length == 0) && 
						<>
							<p>Components have yet to be added to the dashboard.</p>
							<p>You can contact us, manage your account, and logout from the navigation bar.</p>
						</>
					}

					{ !isLoading && components.map((component) =>
						<div key={component.id} className={
							(
								(component.name == "Analysis by County") ||
								(component.name == "DataSnap")
							) ? "homecard_feature large" :
							(
								(component.name == "ML Articles & Perspective") ||
								(component.name == "ML Research & Reports")
							) ? "homecard large" :
							"homecard small"
						}>
							<HomeCard icon={component.icon}>{component.name}</HomeCard>
						</div>
					)}

				</div>
			</div>
		</main>
	)
}

export default Home;