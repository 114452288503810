/**
***************************************************************************
* NAME 			: DashboardsAvailable
* PROPS	 		: dashboards (array)
*		 		: onChangeDashboard (onChangeDashboard) - REMOVED
* RETURNS 		: Lists the available dashboards in the navigation bar
* LAST MODIFIED 	: February 22, 2024
***************************************************************************
*/

import { useContext } from 'react';
import { Link } from 'react-router-dom'; // NavLink sets css class on the active anchor item
import AuthContext from '../../../context/auth-context';

const DashboardsAvailable = (props) => {
	// console.log('DASHBOARD PROPS');
	// console.log(props);

	const authCtx = useContext(AuthContext);

	/**
	**********
	ACCORDION
	**********
	*/
	const accordionHandler = () => {
		const triggerContainer = document.getElementById("dashboard-accordion-trigger");
		const accordionContainer = document.getElementById("dashboard-accordion-content");

		triggerContainer.classList.toggle("open");

		if (triggerContainer.classList.contains('open')) {
			accordionContainer.setAttribute("aria-hidden", "false");
			accordionContainer.removeAttribute("inert");
		} else {
			accordionContainer.setAttribute("aria-hidden", "true");
			accordionContainer.setAttribute("inert", "true");
		}

		if (!accordionContainer.classList.contains('open')) {
			accordionContainer.classList.add('open');
			accordionContainer.style.height = 'auto';
		
			let height = accordionContainer.clientHeight + 'px';
		
			accordionContainer.style.height = '0px';
		
			setTimeout(function () {
				accordionContainer.style.height = height;
			}, 0);
		} else {
			accordionContainer.style.height = '0px';		
			accordionContainer.addEventListener('transitionend', function () {
				accordionContainer.classList.remove('open');
			}, {
				once: true
			});
		}
	};

	/**
	**********
	HANDLERS
	**********
	*/
	const dashboardHandler = (event, key) => {
		event.preventDefault();
		accordionHandler();
		authCtx.updateDashboard(key);
		// props.onChangeDashboard();
	};

	return (
		<div className="accordion">
			<div className="accordion-item">
				<button type="button" id="dashboard-accordion-trigger" className="accordiontrigger" aria-controls="dashboard-accordion-content" onClick={accordionHandler}>Select Dashboard</button>
				<div id="dashboard-accordion-content" className="accordion-content" aria-labelledby="dashboard-accordion-trigger" inert="true">
					<div className="contentwrap">
						<ul role="list">
							{props.dashboards.map((dashboard) => 
								<li key={dashboard.id}>
									{(authCtx.dashboard == dashboard.accessKey) &&
										<Link className="active" onClick={(event) => dashboardHandler(event, dashboard.accessKey)}>{dashboard.name}</Link>
									}
									{(authCtx.dashboard != dashboard.accessKey) &&
										<Link onClick={(event) => dashboardHandler(event, dashboard.accessKey)}>{dashboard.name}</Link>
									}
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DashboardsAvailable;