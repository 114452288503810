import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { resetPassword } from '../../../api/authentication';
// import TextField from '@material-ui/core/TextField';

const ForgotPasswordForm = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const { sendRequest, status, data, error } = useHttp(resetPassword);

	useEffect(() => {
		if (status === 'completed') {
			// console.log('FORGOT PASSWORD DATA');
			// console.log(data);
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('FORGOT PASSWORD DATA');
				// console.log(data);

				if (data['status_code'] == 200) {
					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'The password has been reset and sent to the email address provided'
					)

					resetEmail();

					// Return to log in
					props.onRequest(false);
				} else {
					setErrorMessage(data['status']);
				}
			}

			setFormProcessing(false);
		}
	}, [status, error, data]);

	// Requirement types
	const isEmail = value => value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

	const {
		value: enteredEmail,
		isValid: emailIsValid,
		hasError: emailHasError,
		valueChangeHandler: emailChangedHandler,
		inputBlurHandler: emailBlurHandler,
		valueSubmitHandler: emailSubmitHandler,
		reset: resetEmail
	} = useInput(isEmail); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (emailIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
 		emailSubmitHandler();

		if (!formIsValid) {
			setFormErrorMessage(defaultFormErrorMessage);
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		const formData = {
			email: enteredEmail,
			via: "portal"
		};

		sendRequest(formData);
	}

	const backHandler = () => {
		props.onRequest(false);
	}

	const defaultFormErrorMessage = 'Please fix the errors on the form above.';
	const emailLabelClass = emailHasError ? 'form-error' : '';
	const emailErrorClasses = emailHasError ? 'form-description' : 'form-description visually-hidden';
	const formErrorClasses = (emailHasError) ? 'form-description' : 'form-description visually-hidden';
	const errorClasses = (!!errorMessage) ? 'form-description' : 'form-description visually-hidden';

	return (
		<>
			<p className="instructions" aria-live="assertive">Your password will be reset for the Media Logic client portal and sent to the address provided</p>

			<p className="form-required_text visually-hidden">All fields required</p>

			<div className={errorClasses}>
				<p className="form-feedback-sr visually-hidden" aria-live="assertive">{errorMessage}</p>
				<p className="form-feedback-msg" aria-hidden="true">{errorMessage}</p>
			</div>

			<form onSubmit={submitHandler} autoCapitalize="none" autoCorrect="off" spellCheck="false">
				<div>
					<label htmlFor="email" className={emailLabelClass}>
						Email address
						<span className="form-error_icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 44.4"><path d="M49.4 38 28.7 2.1C27.5.1 24.9-.6 22.9.5c-.6.4-1.2.9-1.6 1.6L.6 38c-1.2 2-.5 4.6 1.6 5.8.6.4 1.4.6 2.1.6h41.5c2.4 0 4.3-1.9 4.3-4.3-.1-.7-.3-1.4-.7-2.1zm-24.4.7c-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7zm2.7-11.6c0 1.3-1.1 2.4-2.4 2.4h-.5c-1.3 0-2.4-1.1-2.4-2.4V15.6c0-1.3 1.1-2.4 2.4-2.4h.5c1.3 0 2.4 1.1 2.4 2.4v11.5z" fill="red" className="alert_triangle" /><path fill="none" d="M0 0h50v44.4H0z"/></svg>
						</span>
					</label>
					<input
						type="email"
						name="email"
						id="email"
						aria-describedby="email-error-container"
						aria-required="true"
						className={emailLabelClass}
						onChange={emailChangedHandler}
						onBlur={emailBlurHandler}
						value={enteredEmail}
					/>
					<div id="email-error-container" className={emailErrorClasses}>
						{emailHasError &&
							// <p className="error-text">Email must include @.</p>
							<p className="form-error">Valid email address required</p>
						}
					</div>
				</div>

				<div className={formErrorClasses}>
					<p className="form-feedback-sr visually-hidden" aria-live="assertive">{formErrorMessage}</p>
					<p className="form-feedback-msg" aria-hidden="true">{formErrorMessage}</p>
				</div>

				<div className="button-wrap">
					<button type="submit" className="primary withspinner" disabled={formProcessing}>
						<span>Reset Password</span>
						{formProcessing && <LoadingSpinner />}
					</button>
				</div>
			</form>

			<div className="forgot_pw">
				<button className="backtologin" type="button" onClick={backHandler}>&lt;&nbsp;&nbsp;Back to log in</button>
			</div>
		</>
	)
}

export default ForgotPasswordForm;