/**
***************************************************************************
* NAME 			: Footer
* PROPS	 		: None
* RETURNS 		: Container for the footer
* LAST MODIFIED 	: February 22, 2024
***************************************************************************
*/

import { useState, useEffect } from 'react';
// import NotificationContext from '../context/notification-context';
import useHttp from '../hooks/use-http';
import { getYear } from '../api/footer';

const Footer = () => {
	const [year, setYear] = useState(null);

	const { sendRequest, status, data, error } = useHttp(getYear, true);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	error
				// )
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// )
			} else {
				setYear(data['data']);
			}
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	return (
		<footer>
			<div className="constrained">
				<p>Client Portal Powered by Media Logic. &copy;{year} Media Logic</p>
			</div>
		</footer>
	)
}

export default Footer;
