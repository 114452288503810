const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;

export async function getCampaignReports(pageData) {
	const response = await fetch(`${API_BASE_URL}/campaign-reports/getReports/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the campaign reports.');
	}

	const data = await response.json();

	// if (!data['success']) { // Manually created and returned attributes
	// 	throw new Error(data['status_code'] + ': ' + data['status']);
	// }
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getCampaignReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/campaign-reports/getReport/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the campaign report.');
	}

	const data = await response.json();

	return data;
}

export async function createCampaignReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/campaign-reports/createReport/`, {
		method: 'POST',
		body: pageData,
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not add the campaign report.');
	}

	const data = await response.json();

	return data;
}

export async function modifyCampaignReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/campaign-reports/modifyReport/`, {
		method: 'POST',
		body: pageData,
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the campaign report.');
	}

	const data = await response.json();

	return data;
}

export async function removeCampaignReport(pageData) {
	const response = await fetch(`${API_BASE_URL}/campaign-reports/removeReport/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the campaign report.');
	}

	const data = await response.json();

	return data;
}
