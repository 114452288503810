/**
***************************************************************************
* NAME 			: NotificationBar
* CONTEXT	 		: type (OK || ERROR || WARNING)
*				: message (message to be displayed in the bar)
*				: cssclass (class of notification bar)
*				: icon (image including path to show with message)
*				: alt (text for the alt tag)
*				: dismiss (text for the dismiss button)
* RETURNS 		: Notification bar at the top of the page
* LAST MODIFIED 	: February 23, 2022
***************************************************************************
*/

import { useState, useContext, useEffect, useCallback } from 'react';
import AuthContext from '../../context/auth-context'
import NotificationContext from '../../context/notification-context';
//import Button from './Button';

let notificationTimer;

const NotificationBar = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [visible, setVisible] = useState(false);

	const hideNotification = useCallback(() => {
// console.log("HIDE NOTIFICATION");
		setVisible(false);
		clearTimeout(notificationTimer);
		notifyCtx.clearNotification();
		authCtx.updateExpiration();
	}, [notifyCtx]);

	const showNotification = useCallback(() => {
// console.log("SHOW NOTIFICATION");
		setVisible(true);
		clearTimeout(notificationTimer);
		notificationTimer = setTimeout(hideNotification, notifyCtx.timeOut);
	}, [hideNotification, notifyCtx.timeOut]);

	useEffect(() => {
// console.log('USE EFFECT: type: ' + notifyCtx.type + ', message: ' + notifyCtx.message);
		if ((notifyCtx.type != null) && (notifyCtx.type != '') && (notifyCtx.message != null) && (notifyCtx.message != '')) {
			showNotification();
		}
	}, [notifyCtx.type, notifyCtx.message, showNotification]);
	
	// For the timeout warning
	const displayTimeoutNotification = useCallback(() => {
		notifyCtx.displayNotification(
			"WARNING",
			"You have been inactive for a while and will be signed out shortly",
			300000, // 5 minutes
			"I'm Here"
		)
	}, []); // If the notifyCtx dependancy is added, it will go into a loop (and not work)

	useEffect(() => {
		if (authCtx.timeoutNotification) {
			displayTimeoutNotification();
		} else {
			hideNotification();
		}
	}, [authCtx.timeoutNotification, displayTimeoutNotification]); // Should NOT be dependant on hideNotification (causes other alerts to hide immediately)
	// For the timeout warning - END

	return (
		<>
			{(visible &&
				<div className={notifyCtx.cssclass}>
					<div className="constrained">
						<div className="flexwrap">
							<div className="icon"><img src={notifyCtx.icon} alt={notifyCtx.alt} width="20" height="20" /></div>
							<div className="content">
								<div>
									<p className="title"><strong>{notifyCtx.alt.toUpperCase()}</strong></p>
									<p>{notifyCtx.message}</p>
								</div>
								{(notifyCtx.dismiss != '') &&
									<button type="button" className="close secondary" onClick={hideNotification}>{notifyCtx.dismiss}</button>
								}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default NotificationBar;