/**
***************************************************************************
* NAME 			: HealthcareInsightsSummary
* PROPS	 		: None
* RETURNS 		: Display for healthcare insights on the dashboard
* LAST MODIFIED 	: March 3, 2024
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { callHealthcareInsights } from '../../../api/healthcare-insights';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const HealthcareInsightsSummary = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [insights, setInsights] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { sendRequest, status, data, error } = useHttp(callHealthcareInsights);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	error
				// )
				setErrorMessage(error);
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// )
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
				// console.log('INSIGHT DATA');
				// console.log(data['data']);

				setErrorMessage("");
				setInsights(data['data']);
			}
			setIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	return (
		<>
			{ isLoading && <LoadingSpinner /> }
			{ !isLoading && errorMessage &&
				<p>{errorMessage}</p>
			}

			{ !isLoading && !errorMessage && (insights.length > 0) &&
				<Splide options={ { rewind: true } } aria-label="Media Logic Articles & Perspective">
					{insights.map((insight) => 
						<SplideSlide key={insight.id}>
							<article>
								<div className="article_image">
									<img src={insight.image} alt="" width="720" height="420" />
								</div>
								<div className="article_copy">
									<h3 className="article_head"><Link to={insight.link} className="external" target="_blank">{insight.title}</Link></h3>
									<p>{insight.description}</p>
								</div>
							</article>
						</SplideSlide>
					)}
				</Splide>
			}
			<p><Link to="https://www.medialogic.com/blog/healthcare-marketing/" className="external" target="_blank">View all articles</Link></p>
		</>
	)
}

export default HealthcareInsightsSummary;