const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;

export async function getIndustryNews(pageData) {
	const response = await fetch(`${API_BASE_URL}/industry-news/getNews/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the industry news.');
	}

	const data = await response.json();

	// if (!data['success']) { // Manually created and returned attributes
	// 	throw new Error(data['status_code'] + ': ' + data['status']);
	// }
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getIndustryNewsArticle(pageData) {
	const response = await fetch(`${API_BASE_URL}/industry-news/getArticle/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the industry news article.');
	}

	const data = await response.json();

	return data;
}

export async function createIndustryNews(pageData) {
	const response = await fetch(`${API_BASE_URL}/industry-news/createNews/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not add the industry news.');
	}

	const data = await response.json();

	return data;
}

export async function modifyIndustryNews(pageData) {
	const response = await fetch(`${API_BASE_URL}/industry-news/modifyNews/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the industry news article.');
	}

	const data = await response.json();

	return data;
}

export async function removeIndustryNews(pageData) {
	const response = await fetch(`${API_BASE_URL}/industry-news/removeNews/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the industry news article.');
	}

	const data = await response.json();

	return data;
}
