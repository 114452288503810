import { useState, useContext, useEffect } from 'react';
import AuthContext from '../context/auth-context'
import useHttp from '../hooks/use-http';
import LoginForm from '../components/containers/home/LoginForm';
import BrowserForm from '../components/containers/home/BrowserForm';
import ForgotPasswordForm from '../components/containers/home/ForgotPasswordForm';
import PersonalPinForm from '../components/containers/home/PersonalPinForm';
import { checkRememberMe } from '../api/authentication';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const Login = () => {
	// Set header information and body classes
	document.title = "Client Portal | Powered by Media Logic";
	document.body.className = "login";

	const [initBrowserMessage, setInitBrowserMessage] = useState(null);
	const [loginMessage, setLoginMessage] = useState(null);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [personalPin, setPersonalPin] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const authCtx = useContext(AuthContext);

	const { sendRequest, status, data, error } = useHttp(checkRememberMe);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// setErrorMessage(error);
			} else if (!data['success']) {
				// setErrorMessage(data['status_code'] + ': ' + data['status']);
			// } else if (!data['data']['message']) {
				// setErrorMessage(data['data']['message']);
			} else {
				// console.log('REMEMBER ME');
				// console.log(data['data']);

				if (data['data']['rememberMe']) {
					// console.log('YES');
					setPersonalPin(true);
					setFirstName(data['data']['firstName']);
				}
			}

			setIsLoading(false);
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	const initDataHandler = (message) => {
		// Send the data back to the parent component (now context)
		setInitBrowserMessage(message);
	}

	const loginMessageHandler = (message) => {
		// Send the message to log in
		setLoginMessage(message);
	}

	const passwordHandler = (ind) => {
		// setLoginMessage('The password has been reset and sent to the email address provided');
		setForgotPassword(ind);
	}

	const personalPinHandler = (attempts) => {
		setPersonalPin((personalPin) ? false : true);
		if (attempts >= 3) {
			setFirstName('');
		}
	}

	return (
		<>
			<div className="main_wrapper">
				<header>
					<div className="content_wrap">
						<div className="logo">
							<img src="/img/ml-logo-horz.svg" alt="Media Logic(R)" width="290" height="72" />
						</div>
					</div>
				</header>

				<main>
					<div className="content_wrap">
						<div className="content">
							<h1>Welcome to the<br />Media Logic client portal</h1>
							<p>Here you can find marketing and consumer insights, campaign perfomance metrics and strategic documents.</p>

							<ul className="product_icons">
								<li><img src="/img/metrics-icn.svg" alt="Metrics" width="100" height="100" /></li>
								<li><img src="/img/data-analytics-icn.svg" alt="Data Analytics" width="100" height="100" /></li>
								<li><img src="/img/calendar-icn.svg" alt="Calendar" width="100" height="100" /></li>
								<li><img src="/img/content-seo-icn.svg" alt="Content and SEO" width="100" height="100" /></li>
								<li><img src="/img/creative-design-icn.svg" alt="Creative and Design" width="100" height="100" /></li>
							</ul>
						</div>
					</div>
				</main>
				{/*
				<div className="alert">
					<p><strong>Please note:</strong> We will be closed on Monday, January 1, 2024 for New Year&rsquo;s Day. Happy New Year!</p>
				</div>
				*/}
			</div>

			<div className="login_form">
				<div className="formwrap">
					{ isLoading && <LoadingSpinner /> }
					{!isLoading &&
						<>
							{!authCtx.isLoggedIn && !personalPin && !forgotPassword && <LoginForm onLogin={initDataHandler} onForgot={passwordHandler} onPin={personalPinHandler} pinAvailable={(!!firstName)} loginMessage={loginMessage} />}
							{!authCtx.isLoggedIn && personalPin && !forgotPassword && <PersonalPinForm firstName={firstName} onPassword={personalPinHandler} onMaxAttempts={loginMessageHandler} />}
							{!authCtx.isLoggedIn && forgotPassword && <ForgotPasswordForm onRequest={passwordHandler} />}
							{authCtx.isLoggedIn && !authCtx.trustedBrowser && <BrowserForm initMessage={initBrowserMessage} />}
						</>
					}
				</div>
			</div>
		</>
	)
}

export default Login;