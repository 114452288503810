import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthContextProvider} from './context/auth-context';
import {NotificationContextProvider} from './context/notification-context';
import {BrowserRouter} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<AuthContextProvider>
			<NotificationContextProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</NotificationContextProvider>
		</AuthContextProvider>
	</React.StrictMode>
);

// BugHerd
if (window.location.href.indexOf("nmbefbnexptdremccipl") > -1) {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.src = "https://www.bugherd.com/sidebarv2.js?apikey=wypnj0beeimd7ld4zmdq1w";
	document.head.appendChild(script);
}
 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
