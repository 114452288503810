/**
***************************************************************************
* NAME 			: CreativeLibrarySummary
* PROPS	 		: None
* RETURNS 		: Display the creative library on the dashboard
* LAST MODIFIED 	: April 1, 2024
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { getCreativeLibraryAllItems } from '../../../api/creative-library';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const CreativeLibrarySummary = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [items, setItems] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { sendRequest, status, data, error } = useHttp(getCreativeLibraryAllItems);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	error
				// )
				setErrorMessage(error);
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// )
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
//				console.log('ITEM DATA');
//				console.log(data['data']);

				setErrorMessage("");
				setItems(data['data']);
			}
			setIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token,
			dashboard: authCtx.dashboard,
			orderBy: "latest",
			top: 5
		};

		sendRequest(requestData);
	}, [sendRequest]);

	return (
		<>
			{ isLoading && <LoadingSpinner /> }
			{ !isLoading && errorMessage &&
				<article>
					<div className="article_copy">
						<p>{errorMessage}</p>
					</div>
				</article>
			}

			{ !isLoading && !errorMessage && (items.length == 0) &&
				<article>
					<div className="article_copy">
						<p>No creative library items to display</p>
					</div>
				</article>
			}
			{ !isLoading && !errorMessage && (items.length > 0) &&
				<>
					<Splide options={ { rewind: true } } aria-label="Creative Library">
						{items.map((item) => 
							<SplideSlide key={item.id}>
								<article>
									<div className="article_copy">
										<h2 className="article_head creative-library"><Link to={'/creative-library/' + item.id} className={item.fileClass}>{item.name}</Link></h2>
										<p className="article_projectname">({item.project} campaign)</p>
										<p><em>{item.lastAction} on {item.displayLastActionMoment}</em></p>
									</div>
								</article>
							</SplideSlide>
						)}
					</Splide>

					<p><Link to="/creative-library/">See all</Link></p>
				</>
			}
		</>
	)
}

export default CreativeLibrarySummary;