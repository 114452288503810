import React, { useContext, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import AuthContext from './context/auth-context'
import LoadingSpinner from './components/UI/LoadingSpinner';
import NotificationBar from './components/UI/NotificationBar';

import Header from './includes/Header';
import Footer from './includes/Footer';

// Pages
import Home from './pages/Index';
import Login from './pages/Login';

// Lazy Loaded Imports (must come after regular imports)
const CountyAnalysis = React.lazy(() => import('./pages/county-analysis/Index'));
const IndustryNews = React.lazy(() => import('./pages/industry-news/Index'));
const IndustryNewsModify = React.lazy(() => import('./pages/industry-news/modify/Index'));
const CompetiscanReports = React.lazy(() => import('./pages/competiscan-reports/Index'));
const CompetiscanReport = React.lazy(() => import('./pages/competiscan-reports/Details'));
const CompetiscanReportsModify = React.lazy(() => import('./pages/competiscan-reports/modify/Index'));
const StrategicDecks = React.lazy(() => import('./pages/strategic-decks/Index'));
const StrategicDeck = React.lazy(() => import('./pages/strategic-decks/Details'));
const StrategicDecksModify = React.lazy(() => import('./pages/strategic-decks/modify/Index'));
const CampaignReports = React.lazy(() => import('./pages/campaign-reports/Index'));
const CampaignReport = React.lazy(() => import('./pages/campaign-reports/Details'));
const CampaignReportsModify = React.lazy(() => import('./pages/campaign-reports/modify/Index'));
const CreativeLibrary = React.lazy(() => import('./pages/creative-library/Index'));
const CreativeLibraryItem = React.lazy(() => import('./pages/creative-library/Details'));
const CreativeLibraryModify = React.lazy(() => import('./pages/creative-library/modify/Index'));
const ContactUs = React.lazy(() => import('./pages/contact-us/Index'));
const Users = React.lazy(() => import('./pages/users/Index'));
const UsersModify = React.lazy(() => import('./pages/users/modify/Index'));
const Account = React.lazy(() => import('./pages/account/Index'));

const gtmParameters = {
    gtmId: 'GTM-M92Z8T5T'
};

TagManager.initialize(gtmParameters);

function App() {
	const authCtx = useContext(AuthContext);

	return (
		<>
			<Suspense
				fallback = {
					<div className='centered'>
						<LoadingSpinner />
					</div>
				}
			>
				{authCtx.isLoggedIn && authCtx.trustedBrowser &&
					<NotificationBar />
				}

				<div className="page_wrapper">
					{(!authCtx.isLoggedIn || !authCtx.trustedBrowser) && <Login />}
					{authCtx.isLoggedIn && authCtx.trustedBrowser &&
						<>
							<Header />
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path='/county-analysis/' element={<CountyAnalysis />} />
{/*
								<Route path="/county-anaysis/:insight" element={<DailyStatus />} />
*/}
								<Route path='/industry-news/' element={<IndustryNews />} />
								<Route path="/industry-news/modify/:article" element={<IndustryNewsModify />} />

								<Route path='/competiscan-reports/' element={<CompetiscanReports />} />
								<Route path="/competiscan-reports/:report" element={<CompetiscanReport />} />
								<Route path="/competiscan-reports/modify/:report" element={<CompetiscanReportsModify />} />

								<Route path='/strategic-decks/' element={<StrategicDecks />} />
								<Route path="/strategic-decks/:deck" element={<StrategicDeck />} />
								<Route path="/strategic-decks/modify/:deck" element={<StrategicDecksModify />} />

								<Route path='/campaign-reports/' element={<CampaignReports />} />
								<Route path="/campaign-reports/:report" element={<CampaignReport />} />
								<Route path="/campaign-reports/modify/:report" element={<CampaignReportsModify />} />

								<Route path='/creative-library/' element={<CreativeLibrary />} />
								<Route path='/creative-library/:item' element={<CreativeLibraryItem />} />
								<Route path='/creative-library/modify/:item' element={<CreativeLibraryModify />} />

								<Route path='/contact-us/' element={<ContactUs />} />

								<Route path='/users/' element={<Users />} />
								<Route path='/users/modify/:user' element={<UsersModify />} />

								<Route path='/account/' element={<Account />} />

								<Route path="*" element={<Navigate to="/" replace />} />
							</Routes>

							<Footer />
						</>
					}
				</div>
			</Suspense>
		</>
	);
}

export default App;